/*--------------------
  COMMON STYLES
--------------------*/
@import "../helpers/utils";

*,
*::after,
*::before {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: initial;
}

p {
  margin: 0;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

address {
  font-style: normal;
}

blockquote,
figure {
  margin: 0;
}

cite {
  font-style: normal;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

textarea {
  resize: vertical;
}

input,
select,
textarea,
button {
  padding: 0;
  font: inherit;
  background-clip: padding-box;
  border: 1px solid #666666;
  border-radius: 0;
  outline: none;
  appearance: none;
}

ul,
ol,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

b {
  font-weight: 600;
}

html,
body {
  font-weight: $fw-base;
  font-style: $fs-base;
  font-size: $fz-base;
  line-height: $lh-base;
  font-family: $ff-base;
  color: $neutrals-1;
  background-color: $neutrals-3;
  scroll-behavior: smooth;
}

.overflow-hidden {
  position: relative;
  overflow: hidden;

  &::before {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba($black, 0.5);

    @include media($tabletL) {
      content: "";
    }
  }
}
/*--------------------
  FOOTER
--------------------*/
@import "../helpers/utils";

.footer {
  padding-bottom: 24px;
  color: $neutrals-2;

  &__container {
    display: flex;
    justify-content: space-between;

    @include media($mobileL) {
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }

  &__author {
    display: flex;
    align-items: center;
    margin-left: 15px;
    text-decoration: none;
    outline: none;
    color: inherit;

    @include media($mobileL) {
      margin-top: 16px;
      margin-left: 0;
    }

    svg {
      transition: transform 0.15s linear;
    }

    path {
      transition: fill 0.15s linear;
    }

    &:hover,
    &:focus {
      svg {
        transform: scale(1.05);
      }

      path {
        fill: #88CC00;
      }
    }

    span {
      margin-right: 10px;
    }
  }
}
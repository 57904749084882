/*--------------------
  ABOUT
--------------------*/
@import "../helpers/utils";

.about {
  padding: 122px 0 120px;

  @include media($tabletL) {
    padding: 80px 0;
  }

  &__list {
    margin-bottom: 120px;

    @include media($tabletL) {
      margin-bottom: 80px;
    }
  }

  &__map-list {
    display: flex;
    margin-top: 40px;

    @include media($tabletL) {
      margin-top: 24px;
      flex-direction: column;
      align-items: flex-start;
    }

    > * {
      flex-grow: 1;

      @include media($tabletL) {
        flex-grow: 0;
      }

      &:not(:last-child) {
        margin-right: 16px;

        @include media($tabletL) {
          margin: 0 0 16px;
        }
      }
    }
  }

  &__map-legend {
    display: flex;
    height: 100%;
    padding: 8px 12px 8px 6px;
    font-weight: 600;
    border-radius: 12px;
    background-color: $white;
  }

  &__map-legend-point {
    position: relative;
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-right: 2px;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background-color: $neutrals-1;
      transform: translate(-50%, -50%);
    }

    &--working {
      &::before {
        background-color: $success;
      }
    }

    &--invaded {
      &::before {
        background-color: $neutrals-2;
      }
    }

    &--closed {
      &::before {
        background-color: $alert;
      }
    }

    &--damaged {
      &::before {
        background-color: $error;
      }
    }
  }

  &__row {
    margin-bottom: 160px;

    @include media($tabletL) {
      margin-bottom: 80px;
    }
  }

  &__subtitle {
    text-align: center;
    margin-bottom: 64px;

    @include media($tabletL) {
      margin-bottom: 40px;
    }
  }
}
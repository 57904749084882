/*--------------------
  HELP
--------------------*/
@import "../helpers/utils";

.help {
  padding: 120px 0;
  border-radius: 0 0 40px 40px;
  background-color: $white;

  @include media($tabletL) {
    padding: 80px 0;
  }

  &__title {
    margin-bottom: 64px;

    @include media($tabletL) {
      margin-bottom: 40px;
    }
  }

  &__list {
    margin-top: 24px;
    margin-bottom: 64px;
    padding: 24px;
    background: $primary-3;
    border-radius: 24px;

    @include media($tabletL) {
      margin-top: 16px;
      margin-bottom: 40px;
      padding: 16px;
    }
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 24px;

      @include media($tabletL) {
        margin-bottom: 16px;
      }
    }

    > *:not(:last-child)  {
      margin-bottom: 8px;
    }
  }

  &__subtitle {
    margin-bottom: 16px;

    @include media($tabletL) {
      margin-bottom: 8px;
    }
  }

   &__info {
     display: flex;
     font-weight: 600;
     word-break: break-all;

     .js-copy {
       width: 34px;
       min-width: 34px;
       height: 28px;
       background-color: $neutrals-1;
       mask-image: url("../icons/copy.svg");
       mask-position: center;
       mask-repeat: no-repeat;
       cursor: pointer;
       transition: transform 0.15s linear, background-color 0.15s linear;

       &:hover,
       &:focus {
         transform: scale(1.1);
       }
     }
   }

  &__link {
    display: flex;
    font-weight: 600;
    text-decoration: none;
    color: inherit;
    outline: none;

    span {
      width: 34px;
      min-width: 34px;
      height: 28px;
      background-color: $neutrals-1;
      mask-image: url("../icons/link.svg");
      mask-position: center;
      mask-repeat: no-repeat;
      cursor: pointer;
      transition: transform 0.15s linear;

      &:hover,
      &:focus {
        transform: scale(1.1);
      }
    }

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
/*--------------------
  TEXT
--------------------*/
@import "../helpers/utils";

.lead {
  font-size: 20px;
  line-height: 30px;

  @include media($tabletL) {
    font-size: 18px;
    line-height: 28px;
  }
}
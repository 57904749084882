/*--------------------
  HERO
--------------------*/
@import "../helpers/utils";

.hero {
  color: $Slava;
  background-image: linear-gradient(to top, $neutrals-3 50%, $white 50%);

  &__wrapper {
    padding: 165px 0;
    border-radius: 40px;
    background-color: $Ukraine;

    @include media($tabletL) {
      padding: 102px 0;
    }
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    max-width: 1020px;

    @include media($mobileM) {
      align-items: stretch;
    }
  }

  &__title {
    margin-bottom: 40px;

    @include media($tabletL) {
      margin-bottom: 24px;
    }
  }

  &__description {
    max-width: 780px;
    margin-bottom: 40px;

    @include media($tabletL) {
      margin-bottom: 24px;
    }
  }
}
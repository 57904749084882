/*--------------------
  MEDICINE
--------------------*/
@import "../helpers/utils";

.medicine {
  background-image: linear-gradient(to top, $white 50%, $neutrals-3 50%);

  &__header {
    margin-bottom: 64px;

    @include media($tabletL) {
      margin-bottom: 40px;
    }
  }

  &__title {
    @include media($tabletL) {
      margin-bottom: 16px;
    }
  }

  &__list {
    margin-bottom: 80px;
  }

  &__promo {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 80px 56px;
    background: $Ukraine;
    border-radius: 24px;
    text-align: center;
    
    &::before,
    &::after {
      content: "";
      position: absolute;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &::before {
      bottom: 0;
      right: 0;
      width: 320px;
      height: 320px;
      background-image: url("/src/assets/images/pills-big.png");
      transform: translate(30%, 40%)  rotate(150deg);

      @include media($tabletL) {
        width: 220px;
        height: 220px;
        transform: translate(45%, 60%)  rotate(150deg);
      }
    }

    &::after {
      top: 0;
      left: 0;
      width: 240px;
      height: 240px;
      background-image: url("/src/assets/images/pills.png");
      transform: translate(-50%, -50%) rotate(-105deg);

      @include media($tabletL) {
        width: 140px;
        height: 140px;
      }
    }

    @include media($tabletL) {
      padding: 32px 16px;
    }

    > * {
      &:not(:last-child) {
        margin-bottom: 40px;

        @include media($tabletL) {
          margin-bottom: 16px;
        }
      }
    }
  }

  &__subtitle {
    color: $Slava;
  }

  &__wrapper {
    overflow: hidden;
    padding: 120px 0;
    border-radius: 40px;
    background-color: $primary-3;

    @include media($tabletL) {
      padding: 80px 0 120px;
    }
  }
}
/*--------------------
  MIXINS
--------------------*/

// LESS MIXINS EXAMPLE .size(20px, 20px)
// .size(@width, @height) {
//   width: @width;
//   height: @height;
// }

// SASS,SCSS MIXINS EXAMPLE @include size(20px, 20px)
// @mixin size($width, $height) {
//   width: $width;
//   height: $height;
// }

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin media($widthValue, $width: max-width) {
  @media only screen and ($width: $widthValue) {
    @content;
  }
}

@mixin font-face($font-name, $path, $weight: 400, $style: normal) {
  @font-face {
    font-weight: $weight;
    font-family: $font-name;
    font-style: $style;
    src: url(#{$path}.woff2) format("woff2"),
      url(#{$path}.woff) format("woff"),
      url(#{$path}.ttf) format("truetype");
    font-display: swap;
    unicode-range: U+000-5FF; /* Latin glyphs */
  }
}
/*--------------------
  BUTTON
--------------------*/
@import "../helpers/utils";

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 30px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  background-color: transparent;
  border: 2px solid transparent;
  text-decoration: none;
  outline: none;
  color: inherit;
  cursor: pointer;
  border-radius: 12px;
  transition: border-color 0.15s linear, background-color 0.15s linear, color 0.15s linear;

  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 4px;
    mask-position: center;
    mask-repeat: no-repeat;
    transition: background-color 0.15s linear;

    &--facebook {
      mask-image: url("../icons/fb.svg");
    }

    &--telegram {
      mask-image: url("../icons/telegram.svg");

    }
  }

  &--small {
    padding: 8px 14px;
  }

  &--dark {
    border-color: $neutrals-1;

    .button__icon {
      background-color: $neutrals-1;
    }

    &:hover,
    &:focus {
      color: $white;
      background-color: $neutrals-1;

      .button__icon {
        background-color: $white;
      }
    }
  }

  &--ukraine {
    background-color: $Ukraine;
    border-color: $Ukraine;

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }

  &--slava {
    color: $white;
    background-color: $Slava;
    border-color: $Slava;

    &:hover,
    &:focus {
      color: $Slava;
      background-color: transparent;
    }
  }
}
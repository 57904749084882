html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

@font-face {
  font-weight: 400;
  font-family: Inter;
  font-style: normal;
  src: url("Inter-Regular.24f3c0ad.woff2") format("woff2"), url("Inter-Regular.7800c74a.woff") format("woff"), url("Inter-Regular.b298cd52.ttf") format("truetype");
  font-display: swap;
  unicode-range: U+0-5FF;
}

@font-face {
  font-weight: 600;
  font-family: Inter;
  font-style: normal;
  src: url("Inter-SemiBold.c094dc20.woff2") format("woff2"), url("Inter-SemiBold.42578e71.woff") format("woff"), url("Inter-SemiBold.6f0ff306.ttf") format("truetype");
  font-display: swap;
  unicode-range: U+0-5FF;
}

*, :after, :before {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: initial;
  margin: 0;
}

p {
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

address {
  font-style: normal;
}

blockquote, figure {
  margin: 0;
}

cite {
  font-style: normal;
}

audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

textarea {
  resize: vertical;
}

input, select, textarea, button {
  font: inherit;
  appearance: none;
  background-clip: padding-box;
  border: 1px solid #666;
  border-radius: 0;
  outline: none;
  padding: 0;
}

ul, ol, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

b {
  font-weight: 600;
}

html, body {
  color: #1f2124;
  scroll-behavior: smooth;
  background-color: #f1f1f2;
  font-family: Inter, Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.overflow-hidden {
  position: relative;
  overflow: hidden;
}

.overflow-hidden:before {
  z-index: 5;
  width: 100%;
  height: 100vh;
  background-color: #00000080;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 768px) {
  .overflow-hidden:before {
    content: "";
  }
}

.button {
  color: inherit;
  cursor: pointer;
  background-color: #0000;
  border: 2px solid #0000;
  border-radius: 12px;
  outline: none;
  justify-content: center;
  align-items: center;
  padding: 14px 30px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-decoration: none;
  transition: border-color .15s linear, background-color .15s linear, color .15s linear;
  display: flex;
}

.button__icon {
  width: 24px;
  height: 24px;
  margin-right: 4px;
  transition: background-color .15s linear;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.button__icon--facebook {
  -webkit-mask-image: url("fb.da838197.svg");
  mask-image: url("fb.da838197.svg");
}

.button__icon--telegram {
  -webkit-mask-image: url("telegram.aeb33eaa.svg");
  mask-image: url("telegram.aeb33eaa.svg");
}

.button--small {
  padding: 8px 14px;
}

.button--dark {
  border-color: #1f2124;
}

.button--dark .button__icon {
  background-color: #1f2124;
}

.button--dark:hover, .button--dark:focus {
  color: #fff;
  background-color: #1f2124;
}

.button--dark:hover .button__icon, .button--dark:focus .button__icon {
  background-color: #fff;
}

.button--ukraine {
  background-color: #ffda0e;
  border-color: #ffda0e;
}

.button--ukraine:hover, .button--ukraine:focus {
  background-color: #0000;
}

.button--slava {
  color: #fff;
  background-color: #0057b7;
  border-color: #0057b7;
}

.button--slava:hover, .button--slava:focus {
  color: #0057b7;
  background-color: #0000;
}

.container {
  width: 100%;
  max-width: 1230px;
  margin: 0 auto;
  padding: 0 15px;
  position: relative;
}

.lead {
  font-size: 20px;
  line-height: 30px;
}

@media only screen and (max-width: 768px) {
  .lead {
    font-size: 18px;
    line-height: 28px;
  }
}

.h1 {
  color: inherit;
  font-size: 64px;
  font-weight: 600;
  line-height: 74px;
}

@media only screen and (max-width: 768px) {
  .h1 {
    font-size: 50px;
    line-height: 60px;
  }
}

.h2 {
  color: inherit;
  font-size: 50px;
  font-weight: 600;
  line-height: 60px;
}

@media only screen and (max-width: 768px) {
  .h2 {
    font-size: 36px;
    line-height: 46px;
  }
}

.h3 {
  color: inherit;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
}

@media only screen and (max-width: 768px) {
  .h3 {
    font-size: 22px;
    line-height: 32px;
  }
}

.row {
  justify-content: space-between;
  margin-left: -40px;
  display: flex;
}

@media only screen and (max-width: 768px) {
  .row {
    flex-direction: column;
    margin: 0;
  }
}

.row > * {
  max-width: 580px;
  width: calc(50% - 40px);
  margin-left: 40px;
}

@media only screen and (max-width: 768px) {
  .row > * {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }
}

.row--decor > :first-child {
  max-height: 100%;
  background-color: #fff;
  border-radius: 12px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  padding: 30px 56px;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .row--decor > :first-child {
    padding: 24px 16px;
  }
}

@media only screen and (max-width: 768px) {
  .row--decor > :first-child {
    margin-bottom: 40px;
  }
}

.row--decor h2 {
  margin-bottom: 24px;
}

@media only screen and (max-width: 768px) {
  .row--decor h2 {
    margin-bottom: 16px;
  }
}

.row--decor img {
  border-radius: 12px;
}

@media only screen and (max-width: 768px) {
  .row--decor img {
    margin: 0 auto;
  }
}

.list {
  flex-wrap: wrap;
  margin-bottom: -40px;
  margin-left: -40px;
  display: flex;
}

@media only screen and (max-width: 768px) {
  .list {
    margin: 0;
  }
}

.list__item {
  width: calc(50% - 40px);
  background-color: #fff;
  border-radius: 12px;
  margin-bottom: 40px;
  margin-left: 40px;
  padding: 24px;
}

@media only screen and (max-width: 768px) {
  .list__item {
    width: 100%;
    margin: 0;
    padding: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .list__item:not(:last-child) {
    margin-bottom: 16px;
  }
}

.header {
  background-color: #fff;
  padding: 8px 0;
}

@media only screen and (max-width: 768px) {
  .header {
    z-index: 15;
    padding: 16px 0;
    position: relative;
  }
}

.header__logo {
  outline: none;
  margin-right: 15px;
  transition: transform .15s linear;
}

@media only screen and (max-width: 768px) {
  .header__logo img {
    max-width: 98px;
  }
}

.header__logo:hover, .header__logo:focus {
  transform: scale(1.05);
}

.header__container {
  align-items: center;
  display: flex;
}

.header__btn-menu {
  width: 18px;
  height: 14px;
  cursor: pointer;
  background-color: #0000;
  border: none;
  margin-left: auto;
  font-size: 0;
  display: none;
  position: relative;
}

@media only screen and (max-width: 768px) {
  .header__btn-menu {
    display: flex;
  }
}

.header__btn-menu span {
  width: 100%;
  height: 2px;
  background-color: #1f2124;
  border-radius: 8px;
  transition: transform .15s linear, top .15s linear;
  position: absolute;
  top: 0;
  left: 0;
}

.header__btn-menu span:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}

.header__btn-menu span:last-child {
  top: auto;
  bottom: 0;
}

.header__btn-menu--close span {
  top: 50%;
  transform: rotate(-45deg);
}

.header__btn-menu--close span:last-child {
  top: 50%;
  transform: rotate(45deg);
}

.header__btn-menu--close span:nth-child(2) {
  opacity: 0;
}

.header__nav {
  width: 100%;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

@media only screen and (max-width: 768px) {
  .header__nav {
    z-index: 10;
    background-color: #fff;
    border-radius: 0 0 40px 40px;
    flex-direction: column;
    align-items: center;
    padding: 24px 16px 32px;
    display: none;
    position: absolute;
    top: calc(100% + 16px);
    left: 0;
  }
}

.header__nav--open {
  display: flex;
}

.header__list {
  align-items: center;
  margin: 0 auto;
  display: flex;
}

@media only screen and (max-width: 768px) {
  .header__list {
    flex-direction: column;
    margin: 0;
  }
}

.header__item:not(:last-child) {
  margin-right: 40px;
}

@media only screen and (max-width: 992px) {
  .header__item:not(:last-child) {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 768px) {
  .header__item:not(:last-child) {
    margin: 0 0 32px;
  }
}

.header__link {
  color: inherit;
  outline: none;
  font-weight: 600;
  text-decoration: none;
}

.header__link:hover, .header__link:focus {
  text-decoration: underline;
}

.header__btn-donate {
  margin-left: 15px;
}

@media only screen and (max-width: 768px) {
  .header__btn-donate {
    margin: 32px 0 0;
  }
}

.footer {
  color: #585866;
  padding-bottom: 24px;
}

.footer__container {
  justify-content: space-between;
  display: flex;
}

@media only screen and (max-width: 425px) {
  .footer__container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.footer__author {
  color: inherit;
  outline: none;
  align-items: center;
  margin-left: 15px;
  text-decoration: none;
  display: flex;
}

@media only screen and (max-width: 425px) {
  .footer__author {
    margin-top: 16px;
    margin-left: 0;
  }
}

.footer__author svg {
  transition: transform .15s linear;
}

.footer__author path {
  transition: fill .15s linear;
}

.footer__author:hover svg, .footer__author:focus svg {
  transform: scale(1.05);
}

.footer__author:hover path, .footer__author:focus path {
  fill: #8c0;
}

.footer__author span {
  margin-right: 10px;
}

.hero {
  color: #0057b7;
  background-image: linear-gradient(to top, #f1f1f2 50%, #fff 50%);
}

.hero__wrapper {
  background-color: #ffda0e;
  border-radius: 40px;
  padding: 165px 0;
}

@media only screen and (max-width: 768px) {
  .hero__wrapper {
    padding: 102px 0;
  }
}

.hero__container {
  text-align: center;
  max-width: 1020px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 375px) {
  .hero__container {
    align-items: stretch;
  }
}

.hero__title {
  margin-bottom: 40px;
}

@media only screen and (max-width: 768px) {
  .hero__title {
    margin-bottom: 24px;
  }
}

.hero__description {
  max-width: 780px;
  margin-bottom: 40px;
}

@media only screen and (max-width: 768px) {
  .hero__description {
    margin-bottom: 24px;
  }
}

.about {
  padding: 122px 0 120px;
}

@media only screen and (max-width: 768px) {
  .about {
    padding: 80px 0;
  }
}

.about__list {
  margin-bottom: 120px;
}

@media only screen and (max-width: 768px) {
  .about__list {
    margin-bottom: 80px;
  }
}

.about__map-list {
  margin-top: 40px;
  display: flex;
}

@media only screen and (max-width: 768px) {
  .about__map-list {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 24px;
  }
}

.about__map-list > * {
  flex-grow: 1;
}

@media only screen and (max-width: 768px) {
  .about__map-list > * {
    flex-grow: 0;
  }
}

.about__map-list > :not(:last-child) {
  margin-right: 16px;
}

@media only screen and (max-width: 768px) {
  .about__map-list > :not(:last-child) {
    margin: 0 0 16px;
  }
}

.about__map-legend {
  height: 100%;
  background-color: #fff;
  border-radius: 12px;
  padding: 8px 12px 8px 6px;
  font-weight: 600;
  display: flex;
}

.about__map-legend-point {
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin-right: 2px;
  position: relative;
}

.about__map-legend-point:before {
  content: "";
  width: 8px;
  height: 8px;
  background-color: #1f2124;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.about__map-legend-point--working:before {
  background-color: #74b224;
}

.about__map-legend-point--invaded:before {
  background-color: #585866;
}

.about__map-legend-point--closed:before {
  background-color: #ff6b00;
}

.about__map-legend-point--damaged:before {
  background-color: #ff2f00;
}

.about__row {
  margin-bottom: 160px;
}

@media only screen and (max-width: 768px) {
  .about__row {
    margin-bottom: 80px;
  }
}

.about__subtitle {
  text-align: center;
  margin-bottom: 64px;
}

@media only screen and (max-width: 768px) {
  .about__subtitle {
    margin-bottom: 40px;
  }
}

.wedo {
  background-color: #fff;
  padding-top: 120px;
}

@media only screen and (max-width: 768px) {
  .wedo {
    padding-top: 80px;
  }
}

.wedo__title {
  text-align: center;
  margin-bottom: 64px;
}

@media only screen and (max-width: 768px) {
  .wedo__title {
    margin-bottom: 40px;
  }
}

.wedo__list {
  flex-wrap: wrap;
  margin-bottom: -40px;
  margin-left: -40px;
  display: flex;
}

@media only screen and (max-width: 768px) {
  .wedo__list {
    margin: 0;
  }
}

.wedo__item {
  width: calc(33.33% - 40px);
  margin-bottom: 40px;
  margin-left: 40px;
}

@media only screen and (max-width: 768px) {
  .wedo__item {
    width: 100%;
    margin: 0;
  }
}

@media only screen and (max-width: 768px) {
  .wedo__item:not(:last-child) {
    margin-bottom: 16px;
  }
}

.wedo__card {
  height: 100%;
  background-color: #ffda0e;
  border-radius: 12px;
  padding: 24px;
}

.wedo__card-counter {
  color: #0057b7;
}

.wedo__promo {
  background-color: #fff8cf;
  border-radius: 12px;
  padding: 24px;
}

.wedo__promo-links {
  margin-top: 16px;
  margin-left: -16px;
  display: flex;
}

@media only screen and (max-width: 1200px) {
  .wedo__promo-links {
    flex-direction: column;
    margin: 0;
  }
}

@media only screen and (max-width: 768px) {
  .wedo__promo-links {
    flex-direction: row;
    margin-left: -16px;
  }
}

@media only screen and (max-width: 425px) {
  .wedo__promo-links {
    flex-direction: column;
    margin: 0;
  }
}

.wedo__promo-item {
  width: 100%;
  margin-left: 16px;
}

@media only screen and (max-width: 1200px) {
  .wedo__promo-item {
    margin: 16px 0 0;
  }
}

@media only screen and (max-width: 768px) {
  .wedo__promo-item {
    margin-left: 16px;
  }
}

@media only screen and (max-width: 425px) {
  .wedo__promo-item {
    margin-left: 0;
  }
}

.wedo__img {
  margin-top: 120px;
}

@media only screen and (max-width: 768px) {
  .wedo__img {
    margin-top: 80px;
  }
}

.help {
  background-color: #fff;
  border-radius: 0 0 40px 40px;
  padding: 120px 0;
}

@media only screen and (max-width: 768px) {
  .help {
    padding: 80px 0;
  }
}

.help__title {
  margin-bottom: 64px;
}

@media only screen and (max-width: 768px) {
  .help__title {
    margin-bottom: 40px;
  }
}

.help__list {
  background: #fff8cf;
  border-radius: 24px;
  margin-top: 24px;
  margin-bottom: 64px;
  padding: 24px;
}

@media only screen and (max-width: 768px) {
  .help__list {
    margin-top: 16px;
    margin-bottom: 40px;
    padding: 16px;
  }
}

.help__item:not(:last-child) {
  margin-bottom: 24px;
}

@media only screen and (max-width: 768px) {
  .help__item:not(:last-child) {
    margin-bottom: 16px;
  }
}

.help__item > :not(:last-child) {
  margin-bottom: 8px;
}

.help__subtitle {
  margin-bottom: 16px;
}

@media only screen and (max-width: 768px) {
  .help__subtitle {
    margin-bottom: 8px;
  }
}

.help__info {
  word-break: break-all;
  font-weight: 600;
  display: flex;
}

.help__info .js-copy {
  width: 34px;
  min-width: 34px;
  height: 28px;
  cursor: pointer;
  background-color: #1f2124;
  transition: transform .15s linear, background-color .15s linear;
  -webkit-mask-image: url("copy.08ad9018.svg");
  mask-image: url("copy.08ad9018.svg");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.help__info .js-copy:hover, .help__info .js-copy:focus {
  transform: scale(1.1);
}

.help__link {
  color: inherit;
  outline: none;
  font-weight: 600;
  text-decoration: none;
  display: flex;
}

.help__link span {
  width: 34px;
  min-width: 34px;
  height: 28px;
  cursor: pointer;
  background-color: #1f2124;
  transition: transform .15s linear;
  -webkit-mask-image: url("link.665bdec0.svg");
  mask-image: url("link.665bdec0.svg");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.help__link span:hover, .help__link span:focus {
  transform: scale(1.1);
}

.help__link:hover, .help__link:focus {
  text-decoration: underline;
}

.info {
  padding-top: 120px;
}

@media only screen and (max-width: 768px) {
  .info {
    padding-top: 80px;
  }
}

.info__row {
  align-items: center;
  margin-left: -40px;
  display: flex;
}

@media only screen and (max-width: 768px) {
  .info__row {
    flex-direction: column;
    margin: 0;
  }
}

.info__row > * {
  width: calc(50% - 40px);
  max-width: 580px;
  margin-left: 40px;
}

@media only screen and (max-width: 768px) {
  .info__row > * {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }
}

.info__row > :first-child {
  margin-bottom: 40px;
}

.info__links {
  margin-top: 24px;
  display: flex;
}

@media only screen and (max-width: 480px) {
  .info__links {
    flex-direction: column;
    margin: 0;
  }
}

.info__links-item:not(:last-child) {
  margin-right: 16px;
}

@media only screen and (max-width: 480px) {
  .info__links-item:not(:last-child) {
    margin-right: 0;
  }
}

@media only screen and (max-width: 480px) {
  .info__links-item {
    margin-top: 16px;
  }
}

.info__list {
  flex-wrap: wrap;
  margin-top: 80px;
  margin-bottom: -40px;
  margin-left: -40px;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .info__list {
    margin-bottom: -20px;
    margin-left: -20px;
  }
}

@media only screen and (max-width: 768px) {
  .info__list {
    margin: 40px 0 -16px;
  }
}

.info__item {
  width: calc(25% - 40px);
  margin-bottom: 40px;
  margin-left: 40px;
}

@media only screen and (max-width: 992px) {
  .info__item {
    width: calc(25% - 20px);
    margin-bottom: 20px;
    margin-left: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .info__item {
    width: 100%;
    margin: 0 0 16px;
  }
}

.info__item:nth-last-child(-n+3) {
  width: calc(33.33% - 40px);
}

@media only screen and (max-width: 992px) {
  .info__item:nth-last-child(-n+3) {
    width: calc(33.33% - 20px);
  }
}

@media only screen and (max-width: 768px) {
  .info__item:nth-last-child(-n+3) {
    width: 100%;
  }
}

.info__item:nth-child(2n+1) {
  color: #0057b7;
}

.info__item:nth-child(2n) {
  color: #ffda0e;
}

.info__card {
  height: 100%;
  background-color: #fff;
  border-radius: 12px;
  padding: 24px;
}

@media only screen and (max-width: 992px) {
  .info__card {
    padding: 16px;
  }
}

.info__card-text {
  color: #1f2124;
}

.info__card-counter {
  color: inherit;
}

@media only screen and (max-width: 1200px) {
  .info__card-counter {
    font-size: 40px;
    line-height: 50px;
  }
}

.founder {
  padding: 120px 0 220px;
}

@media only screen and (max-width: 768px) {
  .founder {
    padding: 80px 0;
  }
}

.medicine {
  background-image: linear-gradient(to top, #fff 50%, #f1f1f2 50%);
}

.medicine__header {
  margin-bottom: 64px;
}

@media only screen and (max-width: 768px) {
  .medicine__header {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 768px) {
  .medicine__title {
    margin-bottom: 16px;
  }
}

.medicine__list {
  margin-bottom: 80px;
}

.medicine__promo {
  text-align: center;
  background: #ffda0e;
  border-radius: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 56px;
  display: flex;
  position: relative;
}

.medicine__promo:before, .medicine__promo:after {
  content: "";
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
}

.medicine__promo:before {
  width: 320px;
  height: 320px;
  background-image: url("pills-big.18834453.png");
  bottom: 0;
  right: 0;
  transform: translate(30%, 40%)rotate(150deg);
}

@media only screen and (max-width: 768px) {
  .medicine__promo:before {
    width: 220px;
    height: 220px;
    transform: translate(45%, 60%)rotate(150deg);
  }
}

.medicine__promo:after {
  width: 240px;
  height: 240px;
  background-image: url("pills.c0e081c9.png");
  top: 0;
  left: 0;
  transform: translate(-50%, -50%)rotate(-105deg);
}

@media only screen and (max-width: 768px) {
  .medicine__promo:after {
    width: 140px;
    height: 140px;
  }
}

@media only screen and (max-width: 768px) {
  .medicine__promo {
    padding: 32px 16px;
  }
}

.medicine__promo > :not(:last-child) {
  margin-bottom: 40px;
}

@media only screen and (max-width: 768px) {
  .medicine__promo > :not(:last-child) {
    margin-bottom: 16px;
  }
}

.medicine__subtitle {
  color: #0057b7;
}

.medicine__wrapper {
  background-color: #fff8cf;
  border-radius: 40px;
  padding: 120px 0;
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  .medicine__wrapper {
    padding: 80px 0 120px;
  }
}

/*# sourceMappingURL=index.da1e59ef.css.map */

/*--------------------
  FOUNDER
--------------------*/
@import "../helpers/utils";

.founder {
  padding: 120px 0 220px;

  @include media($tabletL) {
    padding: 80px 0;
  }
}
/*--------------------
  TITLE
--------------------*/
@import "../helpers/utils";

.h1 {
  font-weight: 600;
  font-size: 64px;
  line-height: 74px;
  color: inherit;

  @include media($tabletL) {
    font-size: 50px;
    line-height: 60px;
  }
}

.h2 {
  font-weight: 600;
  font-size: 50px;
  line-height: 60px;
  color: inherit;

  @include media($tabletL) {
    font-size: 36px;
    line-height: 46px;
  }
}

.h3 {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: inherit;

  @include media($tabletL) {
    font-size: 22px;
    line-height: 32px;
  }
}
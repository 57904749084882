/*--------------------
  ROW
--------------------*/
@import "../helpers/utils";

.row {
  display: flex;
  justify-content: space-between;
  margin-left: -40px;

  @include media($tabletL) {
    flex-direction: column;
    margin: 0;
  }

  > * {
    max-width: 580px;
    width: calc(50% - 40px);
    margin-left: 40px;

    @include media($tabletL) {
      margin: 0;
      width: 100%;
      max-width: 100%;
    }
  }

  &--decor {
    > *:first-child {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-self: stretch;
      max-height: 100%;
      padding: 30px 56px;
      border-radius: 12px;
      background-color: $white;

      @include media($laptopS) {
        padding: 24px 16px;
      }

      @include media($tabletL) {
        margin-bottom: 40px;
      }
    }

    h2 {
      margin-bottom: 24px;

      @include media($tabletL) {
        margin-bottom: 16px;
      }
    }

    img {
      border-radius: 12px;

      @include media($tabletL) {
        margin: 0 auto;
      }
    }
  }
}
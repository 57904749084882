/*--------------------
  INFO
--------------------*/
@import "../helpers/utils";

.info {
  padding-top: 120px;

  @include media($tabletL) {
    padding-top: 80px;
  }

  &__row {
    display: flex;
    align-items: center;
    margin-left: -40px;

    @include media($tabletL) {
      flex-direction: column;
      margin: 0;
    }

    > * {
      width: calc(50% - 40px);
      max-width: 580px;
      margin-left: 40px;

      @include media($tabletL) {
        width: 100%;
        max-width: 100%;
        margin: 0;
      }

      &:first-child {
        margin-bottom: 40px;
      }
    }
  }

  &__links {
    display: flex;
    margin-top: 24px;

    @include media($tabletS) {
      flex-direction: column;
      margin: 0;
    }

    &-item {
      &:not(:last-child) {
        margin-right: 16px;

        @include media($tabletS) {
          margin-right: 0;
        }
      }

      @include media($tabletS) {
        margin-top: 16px;
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 80px;
    margin-left: -40px;
    margin-bottom: -40px;

    @include media($laptopS) {
      margin-left: -20px;
      margin-bottom: -20px;
    }

    @include media($tabletL) {
      margin: 40px 0 -16px;
    }
  }

  &__item {
    width: calc(25% - 40px);
    margin-left: 40px;
    margin-bottom: 40px;

    @include media($laptopS) {
      width: calc(25% - 20px);
      margin-left: 20px;
      margin-bottom: 20px;
    }

    @include media($tabletL) {
      width: 100%;
      margin: 0 0 16px;
    }

    &:nth-last-child(-n+3) {
      width: calc(33.33% - 40px);

      @include media($laptopS) {
        width: calc(33.33% - 20px);
      }

      @include media($tabletL) {
        width: 100%;
      }
    }

    &:nth-child(odd) {
      color: $Slava;
    }

    &:nth-child(even) {
      color: $Ukraine;
    }
  }

  &__card {
    height: 100%;
    padding: 24px;
    background-color: $white;
    border-radius: 12px;

    @include media($laptopS) {
      padding: 16px;
    }

    &-text {
      color: $neutrals-1;
    }

    &-counter {
      color: inherit;

      @include media($laptopL) {
        font-size: 40px;
        line-height: 50px;
      }
    }
  }
}
/*--------------------
  HEADER
--------------------*/
@import "../helpers/utils";

.header {
  padding: 8px 0;
  background-color: $white;

  @include media($tabletL) {
    position: relative;
    z-index: 15;
    padding: 16px 0;
  }

  &__logo {
    margin-right: 15px;
    outline: none;
    transition: transform 0.15s linear;

    img {
      @include media($tabletL) {
        max-width: 98px;
      }
    }

    &:hover,
    &:focus {
      transform: scale(1.05);
    }
  }
  
  &__container {
    display: flex;
    align-items: center;
  }

  &__btn-menu {
    position: relative;
    display: none;
    margin-left: auto;
    width: 18px;
    height: 14px;
    font-size: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;

    @include media($tabletL) {
      display: flex;
    }

    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: $neutrals-1;
      border-radius: 8px;
      transition: transform 0.15s linear, top 0.15s linear;

      &:nth-child(2) {
        top: 50%;
        transform: translateY(-50%);
      }

      &:last-child {
        top: auto;
        bottom: 0;
      }
    }

    &--close {
      span {
        top: 50%;
        transform: rotate(-45deg);

        &:last-child {
          top: 50%;
          transform: rotate(45deg);
        }

        &:nth-child(2) {
          opacity: 0;
        }
      }
    }
  }

  &__nav {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    width: 100%;

    @include media($tabletL) {
      position: absolute;
      z-index: 10;
      top: calc(100% + 16px);
      left: 0;
      display: none;
      flex-direction: column;
      align-items: center;
      padding: 24px 16px 32px;
      background-color: $white;
      border-radius: 0 0 40px 40px;
    }

    &--open {
      display: flex;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    margin: 0 auto;

    @include media($tabletL) {
      flex-direction: column;
      margin: 0;
    }
  }

  &__item {
    &:not(:last-child) {
      margin-right: 40px;

      @include media($laptopS) {
        margin-right: 15px;
      }

      @include media($tabletL) {
        margin: 0 0 32px;
      }
    }
  }

  &__link {
    font-weight: 600;
    color: inherit;
    text-decoration: none;
    outline: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &__btn-donate {
    margin-left: 15px;

    @include media($tabletL) {
      margin: 32px 0 0;
    }
  }
}
/*--------------------
  CONTAINER
--------------------*/
@import "../helpers/utils";

.list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -40px;
  margin-bottom: -40px;

  @include media($tabletL) {
    margin: 0;
  }

  &__item {
    width: calc(50% - 40px);
    margin-left: 40px;
    margin-bottom: 40px;
    padding: 24px;
    background-color: $white;
    border-radius: 12px;

    @include media($tabletL) {
      width: 100%;
      padding: 16px;
      margin: 0;
    }

    &:not(:last-child) {
      @include media($tabletL) {
        margin-bottom: 16px;
      }
    }
  }
}